<template>
  <div>
    <div class="loginbox-g center-g">
      <h2 style="font-weight: 400">将向 {{ regphoneUser }} 发送验证码</h2>
      <br />
      <br />
      <el-input v-model="validCode" maxlength="6" placeholder="请输入验证码">
        <template slot="append">
          <el-button type="success" @click="getCode" :disabled="btnBool">{{ this.yzmtext }}</el-button>
        </template>
      </el-input>
      <br />
      <br />
      <br />
      <el-button type="success" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
import { validatePhone, sendPhone } from "@/api/api";
export default {
  data() {
    return {
      biz: {},
      btnBool: false,
      yzmtext: "发送验证码",
      validCode: "",
      regphoneUser: this.$store.getters.getBiz.regphoneUser
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    getCode() {
      const that = this;
      sendPhone({
        regphone: that.regphoneUser,
        type: 6
      }).then(res => {
        if (res.result) {
          that.$alert("验证码已发送", {
            confirmButtonText: "确定",
            type: "success",
            callback: () => {}
          });
          that.btnBool = true;
          let i = 60;
          let timer = setInterval(() => {
            that.yzmtext = i + "s " + "后重新发送";
            i--;
            if (i < 0) {
              that.btnBool = false;
              that.yzmtext = "重新发送";
              clearInterval(timer);
            }
          }, 1000);
        }
      });
    },
    submit() {
      const that = this;
      if (this.validCode === "") {
        this.$alert("请输入验证码", {
          confirmButtonText: "确定",
          type: "warning",
          callback: () => {}
        });
      } else {
        validatePhone({
          regphone: that.regphoneUser,
          vCode: that.validCode
        }).then(res => {
          if (res.result) {
            this.$emit("success");
          }
        });
      }
    }
  }
};
</script>

<style scoped>
</style>
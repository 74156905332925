<template>
  <div>
    <el-page-header @back="back" content="手机号验证" class="pagehead-g"></el-page-header>
    <validatephonetool @success="onSuccess" />
    <el-dialog :visible.sync="qrShow" width="50%" :close-on-click-modal="false">
      <div class="center-g">
        <div>微信扫描二维码，设置扫码微信为收款微信。提现款将汇入该微信号内。</div>
        <div style="margin: 20px;">
          <vue-qr :text="dataObj"></vue-qr>
        </div>
        <div style="margin-bottom: 30px; color: darkorange;">当前二维码有效期为15分钟</div>
        <el-button type="primary" @click="back">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import validatephonetool from "../components/ValidphoneTool.vue";
import VueQr from "vue-qr";
export default {
  components: {
    validatephonetool,
    VueQr
  },
  data() {
    return {
      type: this.$route.query.type,
      qrShow: false,
      biz: this.$store.getters.getBiz,
      dataObj: ""
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    onSuccess() {
      if (this.type == "setphone") {
        this.$router.push({ path: "/sightcompany/setphonesightcompany" });
      } else {
        let now = Date.now();
        this.dataObj =
          "https://server.solar960.com/qrtowechat/setwx/" +
          this.biz.id +
          "|" +
          now;
      }
      this.qrShow = true;
    }
  }
};
</script>

<style>
</style>